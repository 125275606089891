// extracted by mini-css-extract-plugin
export var active = "FellowshipProgramCard-module--active--BLF6g";
export var banner = "FellowshipProgramCard-module--banner--dQt7j";
export var careerFooter = "FellowshipProgramCard-module--careerFooter--ZsESI";
export var customFont = "FellowshipProgramCard-module--customFont--JE+Lh";
export var emailForm = "FellowshipProgramCard-module--emailForm--6wQ-Z";
export var fontInter = "FellowshipProgramCard-module--fontInter--g6Au0";
export var innerDot = "FellowshipProgramCard-module--innerDot--WU6HK";
export var innerDotActive = "FellowshipProgramCard-module--innerDotActive--CXaSd";
export var inputControl = "FellowshipProgramCard-module--inputControl--dI1W+";
export var line = "FellowshipProgramCard-module--line--PNOKN";
export var milestoneCard = "FellowshipProgramCard-module--milestoneCard--iuYDU";
export var programCard = "FellowshipProgramCard-module--programCard--55gxy";
export var skill = "FellowshipProgramCard-module--skill--Fzaul";
export var toggle = "FellowshipProgramCard-module--toggle--wg8+L";
export var toggleButton = "FellowshipProgramCard-module--toggleButton--mQTOf";
export var toggleMobile = "FellowshipProgramCard-module--toggleMobile--TJuj-";