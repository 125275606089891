import Button from '@components/Button/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ESale } from '@src/constants/sale/index';
import { AnimatePresence, motion } from 'framer-motion';
import { Link, navigate } from 'gatsby';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { GTM } from '../../analytics/gtm';
import { gtmEvents } from '../../analytics/gtmEventsConstant';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../../context/GlobalContextProvider';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import * as styles from './ProgramCardRevamped.module.scss';
import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
import { LEAD_GEN_REDEEM_PROGRAM_CARD } from '@src/constants/LeadGenerationConstants/index';

// var showdown = require("showdown");
// var converter = new showdown.Converter();

// const ReactMarkdown = require("react-markdown");

export default function ProgramCardRevamped(props) {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);

  const [inputRef, setInputFocus] = useFocus();
  const [emailForm, setemailForm] = useState(false);
  const [email, setEmail] = useState('');
  const formRef = useRef(null);
  useEffect(() => {
    setemailForm(false);
  }, []);
  let milestones = [];

  const getSaleBanner = (sale) => {
    switch (sale) {
      case 'ONE_YEAR_SALE':
        return (
          <>
            <div
              className="bg-oneyear-sale-200 rounded-t-lg py-3 text-center text-xs text-white "
              style={{ zIndex: 99 }}
            >
              Celebrating 1 year of{' '}
              <span className="text-oneyear-sale-100 font-bold">
                #ChangingDeveloperLives
              </span>
            </div>
            <div
              className=""
              style={{
                marginBottom: '48px',
                height: '40px',
                overflow: 'hidden',
              }}
            >
              <StaticImage
                loading="lazy"
                src="../../images/OneYearSale/Other/ProgramCardStrip.png"
                placeholder="blurred"
                alt="Strip"
              />
            </div>
          </>
        );

      case 'COPY_PASTE_SALE':
        return (
          <>
            <p
              className="rounded-t-lg bg-copy-paste-sale-green-500 py-3 text-center text-xs text-white"
              style={{ zIndex: 99 }}
            >
              <span className="font-bold text-copy-paste-sale-green-100">
                #BreakTheChain
              </span>
              . Don't Copy/Paste Your Career
            </p>
            <div
              className=""
              style={{
                marginBottom: '48px',
                height: '40px',
                overflow: 'hidden',
              }}
            >
              <StaticImage
                loading="lazy"
                src="../../images/OneYearSale/Other/ProgramCardStrip.png"
                placeholder="blurred"
                alt="Strip"
              />
            </div>
          </>
        );

      case ESale.DIWALI_SALE:
        return (
          <>
            <div
              className={` ${styles.customFont} mb-2 rounded-3xl py-2 text-center text-base text-white md:rounded-2xl md:py-3`}
              style={{
                zIndex: 99,
                background:
                  'linear-gradient(145.8deg, #5C147F 0%, #7700AF 98.25%)',
              }}
            >
              <span className="text-diwali-sale-orange-250 font-bold">
                #LightUpYourPortfolio!
              </span>
            </div>
            <div
              className={`${'rounded-t-2xl md:rounded-t-none'}`}
              style={{
                marginBottom: '48px',
                height: '40px',
                overflow: 'hidden',
              }}
            >
              <StaticImage
                loading="lazy"
                src="../../images/OneYearSale/Other/ProgramCardStrip.png"
                placeholder="blurred"
                alt="Strip"
              />
            </div>
          </>
        );

      case ESale.CYBER_SALE:
        return (
          <>
            <div
              className={` ${styles.customFont} mb-2 rounded-3xl py-2 text-center text-base text-white shadow-md md:rounded-2xl md:py-3`}
              style={{
                zIndex: 99,
                background:
                  'linear-gradient(145.8deg, #FFE600 0%, #31DB7D 98.25%)',
              }}
            >
              <span className="text-cyber-sale-green-900 font-bold">
                #RelaunchYourDevCareer
              </span>{' '}
              <span className="text-cyber-sale-green-800 font-bold">
                this Cyber Monday.
              </span>
            </div>
            <div
              className={`${'rounded-t-2xl md:rounded-t-none'}`}
              style={{
                marginBottom: '48px',
                height: '40px',
                overflow: 'hidden',
              }}
            >
              <StaticImage
                loading="lazy"
                src="../../images/OneYearSale/Other/ProgramCardStrip.png"
                placeholder="blurred"
                alt="Strip"
              />
            </div>
          </>
        );

      case ESale.YEAR_OF_DOING_SALE:
        return (
          <>
            <div
              className={`bg-yearOfDoingSale-blue-100 rounded-t-lg py-2 text-center font-rubik text-sm text-white md:rounded-t-2xl`}
              style={{
                zIndex: 99,
              }}
            >
              <span className="text-yearOfDoingSale-gold-100">
                Make 2022 your
              </span>{' '}
              <span className="font-bold text-white">#YearofDoing</span>
            </div>
            <div
              className={``}
              style={{
                marginBottom: '48px',
                height: '40px',
                overflow: 'hidden',
              }}
            >
              <StaticImage
                loading="lazy"
                src="../../images/OneYearSale/Other/ProgramCardStrip.png"
                placeholder="blurred"
                alt="Strip"
              />
            </div>
          </>
        );

      default:
        return (
          <div
            className=""
            style={{
              marginBottom: '48px',
              height: '40px',
              overflow: 'hidden',
            }}
          >
            <GatsbyImage
              loading="lazy"
              image={props.gradient}
              alt={'Header'}
              className="h-full w-full"
            />
          </div>
        );
    }
  };
  const getSaleBottomBanner = (sale) => {
    switch (sale) {
      case ESale.DIWALI_SALE:
        return (
          <div
            className="relative mt-2 w-full rounded-2xl"
            style={{
              background:
                'linear-gradient(145.8deg, #60008D 0%, #860058 98.25%)',
            }}
          >
            <div className="flex  w-3/5 flex-col items-start px-4 pt-4 pb-4 text-left text-white md:px-8">
              <p className="text-diwali-sale-orange-250 font-bold">
                #LightUpYourPortfolio!
              </p>
              <p className="text-diwali-sale-neutral-300 text-xs">
                Earn special scholarship of ₹30,000
              </p>
            </div>
            <div className="absolute right-0 bottom-0 w-32 rounded-br-2xl">
              <StaticImage
                loading="lazy"
                src="../../images/diwali-sale/Fifteen-K.png"
                placeholder="blurred"
                width={150}
                alt="Rs.30000 Scholarship"
                className="rounded-br-2xl"
              />
            </div>
          </div>
        );

      case ESale.CYBER_SALE:
        return (
          <div
            className="relative mt-2 w-full rounded-2xl"
            style={{
              background:
                'linear-gradient(145.8deg, #FFE600 0%, #31DB7D 98.25%)',
            }}
          >
            <div className="flex  w-3/5 flex-col items-start px-4 pt-4 pb-4 text-left text-white md:px-8">
              <p className="text-cyber-sale-green-900 font-bold">
                Get a Rs. 30,000 scholarship after your free trial.
              </p>
              <p className="text-cyber-sale-green-800 text-xs font-bold">
                Limited Time Offer
              </p>
            </div>
            <div className="">
              <StaticImage
                loading="lazy"
                src="../../images/cyber-sale/Seventeen-K.png"
                placeholder="blurred"
                width={170}
                alt="Rs.30000 Scholarship"
                className="absolute right-0 bottom-0 w-40 rounded-br-2xl"
              />
            </div>
          </div>
        );

      case ESale.YEAR_OF_DOING_SALE:
        return (
          <div className="bg-yearOfDoingSale-blue-100 rounded-b-xl pb-4">
            <p className="px-9 pt-4 pb-6 text-center text-sm text-white md:px-8">
              Get a{' '}
              <u className="text-yearOfDoingSale-gold-100 font-bold">
                ₹ 30,000 scholarship
              </u>{' '}
              after your free trial. <br className="hidden md:block" />
              <span className="font-bold">Limited Time Offer.</span>
            </p>
            <div className="absolute bottom-[-25px] left-[40%] w-16 md:bottom-[-40px]">
              <StaticImage
                loading="lazy"
                src="../../images/year-of-doing-sale/Motif.png"
                placeholder="blurred"
                alt="Post Card"
              />
            </div>
          </div>
        );

      default:
        return (
          <div className="bg-copy-paste-sale-green-500 pb-4">
            <p className="px-9 pt-4 pb-8 text-center text-white md:px-8">
              Get a{' '}
              <u className="font-bold text-copy-paste-sale-yellow-100">
                ₹ 30,000 scholarship
              </u>{' '}
              after your free trial.
            </p>
            <div className="absolute bottom-[-25px] left-[40%] w-16 md:bottom-[-40px]">
              <StaticImage
                loading="lazy"
                src="../../images/PostCardBadge.png"
                placeholder="blurred"
                alt="Post Card"
              />
            </div>
          </div>
        );
    }
  };

  props.data &&
    props.data.milestones &&
    props.data.milestones.map((ele, idx) =>
      milestones.push(
        <div
          className={`mt-3  pt-24 pl-5 pb-24 pr-4 ${styles.milestoneCard}`}
          key={idx}
        >
          <h1 className=" font-normal">
            {idx + 1}. {ele.title}
          </h1>
          <p className="">{ele.description}</p>
          <div className={` flex flex-wrap items-center `}>
            {ele.skills.map((skill, index) => (
              <div key={index} className={`${styles.skill}`}>
                {skill}
              </div>
            ))}
          </div>
        </div>,
      ),
    );

  return (
    <>
      <div className="relative">
        <div
          className={`${styles.programCard} bg-crio-neutral-100 text-left ${
            props.sale === ESale.DIWALI_SALE ? 'rounded-2xl md:rounded-sm' : ''
          }`}
        >
          <div className="bg-crio-neutral-75">{getSaleBanner(props.sale)}</div>
          <div className="flex h-[100%] flex-col justify-between">
            <div>
              <div
                className={`${styles.banner} -mt-20 flex items-center md:-mt-16`}
              >
                <div className="flex w-2/12 md:w-14">
                  <GatsbyImage
                    loading="lazy"
                    image={props.data.image}
                    alt={'Avatar'}
                    className={`mr-3`}
                  />
                </div>
                <div className="">
                  <h6 className="block font-semibold text-crio-green-700 md:hidden">
                    {props.data.cardTitle}
                  </h6>
                  <h5 className="hidden font-semibold text-crio-green-700 md:block">
                    {props.data.cardTitle}
                  </h5>
                </div>
              </div>
              <div>
                <p className="px-5 py-2 leading-loose ">{props.data.content}</p>
                <div className=" m-0 px-5  py-2 text-left ">
                  <div>
                    <div className={`row mb-6 ${styles.customFont} `}>
                      <div className="col-4 pr-1 ">
                        <div className=" flex h-full flex-col justify-between rounded-l-sm bg-crio-neutral-200 p-1 px-3">
                          <div className="my-1">
                            <div className="text-sm text-crio-neutral-600">
                              Trial Session
                            </div>{' '}
                            <div>Free</div>
                          </div>
                          <div className="my-1">
                            <div className="text-sm text-crio-neutral-600">
                              Duration
                            </div>{' '}
                            <div>{props.program?.offeringHours} Hours</div>
                          </div>
                        </div>{' '}
                      </div>{' '}
                      <div className="col-8 pl-0 ">
                        <div className=" flex h-full flex-col justify-between rounded-r-sm bg-crio-neutral-200 p-1 px-3">
                          <div className="my-1">
                            <div className="text-sm text-crio-neutral-600 ">
                              Scholarships
                            </div>{' '}
                            <div>Assured scholarships after trial </div>
                          </div>
                          <div className="my-1">
                            <div className="text-sm text-crio-neutral-600">
                              Career Services
                            </div>{' '}
                            <div>In-built Career Assistance </div>
                          </div>
                        </div>{' '}
                      </div>{' '}
                    </div>
                    <div className="mb-3">
                      <div className={`bodyText2 text-left`}>
                        {props.data.highlights.map((ele, idx) => (
                          <div
                            className={`${
                              idx != props.data.highlights.length - 1
                                ? 'mb-5'
                                : ''
                            } flex `}
                            key={idx}
                          >
                            {' '}
                            <FontAwesomeIcon
                              icon={['fas', ele.icon]}
                              style={{
                                color: '#075353',
                                marginTop: '6px',
                                marginRight: '10px',
                                cursor: 'pointer',
                              }}
                              size="1x"
                            />{' '}
                            <div>{ReactHtmlParser(ele.description)}</div>
                          </div>
                        ))}
                      </div>
                    </div>{' '}
                  </div>
                </div>{' '}
              </div>{' '}
            </div>
            <div className="relative px-3 pb-3">
              <AnimatePresence>
                {emailForm ? (
                  <div className="">
                    <motion.div
                      initial={{ y: 1000, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{
                        type: 'tween',
                        stiffness: 100,
                        duration: 0.6,
                      }}
                      exit={{ y: 1000, opacity: 0 }}
                      className={`${styles.emailForm}  `}
                    >
                      <p className={` mb-2 text-sm`}>
                        Enter your Email Address
                      </p>
                      <form
                        ref={formRef}
                        id="form"
                        onSubmit={(e) => {
                          e.preventDefault();
                          let emailValue = e.target.email.value;
                          dispatch({
                            type: 'SET_EMAIL',
                            payload: emailValue,
                          });
                          GTM.setEmail(emailValue);

                          GTM.login({
                            userId: emailValue,
                            email: emailValue,
                          });
                          GTM.track(gtmEvents.EMAIL_SUBMITTED, {
                            type: props.data.offeringId,
                            location: 'HOME_PROGRAM_CARD',
                          });
                          if (process.env.GATSBY_ENV == 'production') {
                            fetch(
                              `https://script.google.com/macros/s/AKfycbzZu0sfE3ZdlifBokz_l_ei9duEq9YZ3mEN8mf5cDsM8JZyF-2_Et7aiwRW7i7jnQ-6/exec?endpoint=website-leads&email=${emailValue}&country=${
                                state.country
                              }&type=${
                                props.data.offeringId
                              }&location=HOME_PROGRAM_CARD&utm=${encodeURIComponent(
                                state.utm,
                              )}&adblocker=${
                                typeof window.webengage == 'undefined'
                                  ? true
                                  : false
                              }&url=${window.location.href}`,
                            );
                          }
                          let typeform = props.program.typeform;
                          if (
                            state.registrationDetails &&
                            state.registrationDetails[props.data.offeringId]
                          ) {
                            navigate(
                              `/registered/?id=${
                                state.registrationDetails[props.data.offeringId]
                                  .id
                              }`,
                            );
                          } else {
                            navigate('/registration', {
                              state: {
                                link: typeform,
                                utm: props.data.utm,
                                type: props.data.offeringId,
                                location: 'HOME_PROGRAM_CARD',
                                program: props.program,
                              },
                            });
                          }
                        }}
                      >
                        <div className={styles.inputControl}>
                          <input
                            ref={inputRef}
                            type="email"
                            name="email"
                            className=""
                            required
                            style={{ whiteSpace: 'nowrap' }}
                            // style={{ width: isMobile ? "100%" : "50%" }}
                          ></input>
                        </div>

                        <div className=" mt-4 flex   flex-wrap items-center sm:flex-nowrap">
                          {' '}
                          <button type="submit" className="w-full">
                            <Button
                              type="primary"
                              className="md mb-2 w-full sm:mr-2 sm:mb-0"
                              style={{
                                whiteSpace: 'nowrap',
                                background: '#075353',
                              }}
                              onClick={(e) => {
                                dispatch({
                                  type: 'SET_EMAIL',
                                  payload: e.target.email.value,
                                });
                                GTM.setEmail(e.target.email.value);

                                if (formRef.current.requestSubmit) {
                                  formRef.current.requestSubmit();
                                }
                              }}
                            >
                              {props.sale !== 'ONE_YEAR_SALE'
                                ? 'Apply Now'
                                : 'Apply and start for free'}
                            </Button>
                          </button>
                          <Button
                            style={{
                              border: 'solid 1px #075353',
                              color: '#075353',
                            }}
                            hoverbackground="#075353"
                            hovercolor="#fff"
                            // type="outlined"
                            className="md w-full"
                            onClick={(e) => {
                              dispatch({
                                type: 'USER_IS_APPLYING',
                                payload: false,
                              });
                              setemailForm(false);
                              GTM.track(
                                gtmEvents.PROGRAM_ME_EMAIL_FORM_CLOSED,
                                {
                                  meId: props.data.offeringId,
                                  programName: 'DEVSPRINT_4.0',
                                  location: 'HOME_PROGRAM_CARD',
                                },
                              );
                            }}
                            // style={{ width: isMobile ? "50%" : "25%" }}
                          >
                            Close
                          </Button>{' '}
                        </div>
                      </form>
                    </motion.div>{' '}
                  </div>
                ) : (
                  ''
                )}{' '}
                <div className=" flex w-full  flex-wrap items-center gap-4 sm:flex-nowrap ">
                  <div className="flex  w-full ">
                    <LeadGenerationButton
                      text="Apply Now"
                      buttonLocation={LEAD_GEN_REDEEM_PROGRAM_CARD}
                      className="h-[46px] w-full px-12 py-2 md:mb-2"
                      wrapperClassName="w-full"
                    />
                  </div>
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      let link =
                        window.location.origin + '/' + props.program.slugUrl;
                      if (props.newTab) {
                        window.open(link, '_blank');
                      }
                    }}
                    className="w-full"
                  >
                    <Link to={!props.newTab ? '/' + props.program.slugUrl : ''}>
                      <Button
                        // type="outlined"
                        style={{
                          whiteSpace: 'nowrap',
                          // width: isMobile ? "210px" : "150px",
                          border: 'solid 1px #075353',
                          color: '#075353',
                        }}
                        hoverbackground="#075353"
                        hovercolor="#fff"
                        className="md mb-2 w-full "
                        onClick={(e) => {
                          GTM.track(gtmEvents.PROGRAM_ME_MORE_DETAILS_CLICKED, {
                            meId: props.data.offeringId,
                            programName: 'DEVSPRINT_4.0',
                            location: 'HOME_PROGRAM_CARD',
                          });
                        }}
                      >
                        Learn More{' '}
                      </Button>
                    </Link>
                  </div>
                </div>
              </AnimatePresence>
            </div>
          </div>
        </div>{' '}
        {/* {getSaleBottomBanner(props.sale)} */}
      </div>
    </>
  );
}

const useFocus = () => {
  const htmlElRef = useRef(null);
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus();
  };

  return [htmlElRef, setFocus];
};
