// extracted by mini-css-extract-plugin
export var active = "ProgramCardRevamped-module--active--j5NIP";
export var banner = "ProgramCardRevamped-module--banner--7YbZw";
export var careerFooter = "ProgramCardRevamped-module--careerFooter--LXwzr";
export var customFont = "ProgramCardRevamped-module--customFont--FYqLE";
export var emailForm = "ProgramCardRevamped-module--emailForm--t2n6i";
export var innerDot = "ProgramCardRevamped-module--innerDot--unA9e";
export var innerDotActive = "ProgramCardRevamped-module--innerDotActive--xLW1W";
export var inputControl = "ProgramCardRevamped-module--inputControl--79YJP";
export var line = "ProgramCardRevamped-module--line--DgSYH";
export var milestoneCard = "ProgramCardRevamped-module--milestoneCard--Kku0S";
export var programCard = "ProgramCardRevamped-module--programCard--kiXsv";
export var skill = "ProgramCardRevamped-module--skill--IsWcr";
export var toggle = "ProgramCardRevamped-module--toggle--RIDPK";
export var toggleButton = "ProgramCardRevamped-module--toggleButton--Nd6wY";
export var toggleMobile = "ProgramCardRevamped-module--toggleMobile--Usej7";